<template>
  <div>
    <HeaderRules class="mt-8" />
    <!-- listado de promociones -->
    <RulesList class="mt-10" />
  </div>
</template>

<script>
import HeaderRules from "./list/Header.vue";
import RulesList from "./list/RuleList.vue";

export default {
  name: "PromotionsListPAge",
  components: {
    HeaderRules,
    RulesList,
  },
  created() {
    this.$store.dispatch("updatePageTitle", {
      title: this.$t("Lista de Reglas"),
      subtitle: this.$t("Agrega y Administra las reglas de tus promociones"),
    });
  },
};
</script>
