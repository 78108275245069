<template>
  <div>
    <vx-card>
      <div class="mb-4 flex justify-between align-middle">
        <h2 class="w-full text-info">{{ $t("Reglas actuales") }}</h2>

        <div class="flex flex-col justify-end items-end w-full">
          <vs-button
            @click="fetchRules()"
            color="transparent"
            text-color="gray"
          >
            <feather-icon
              icon="RefreshCwIcon"
              :class="{ 'animate-spin': loadingRules }"
            ></feather-icon>
          </vs-button>
          <!-- eslint-disable -->
          <vs-select
            :label="this.$t('items por página')"
            v-model="pagination.page_size"
            class="w-32"
          >
            <vs-select-item
              :key="index"
              :value="item"
              :text="item"
              v-for="(item, index) in maxItemsVals"
            />
          </vs-select>
        </div>
      </div>

      <vs-table
        class="promotions-rules-table"
        :data="rules"
        :noDataText="$t('Datos no disponibles')"
      >
        <template slot="thead">
          <vs-th>{{ $t("Nombre") }}</vs-th>
          <vs-th>{{ $t("Precio Normal") }}</vs-th>
          <vs-th>{{ $t("Cantidad clientes") }}</vs-th>
          <vs-th>{{ $t("Cantidad tiendas") }}</vs-th>
          <vs-th>{{ $t("Estado") }}</vs-th>
          <vs-th></vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" v-bind:key="indextr" v-for="(tr, indextr) in data">
            <!-- nombre -->
            <vs-td :data="data[indextr].name">
              <p>{{ data[indextr].name }}</p>
            </vs-td>

            <!-- porcentaje descuento -->
            <vs-td :data="data[indextr].percent_discount">
              <p class="font-bold">{{ data[indextr].percent_discount }}%</p>
            </vs-td>

            <!-- Cantidad clientes -->
            <vs-td :data="data[indextr].quantity_customers">
              <p>{{ data[indextr].quantity_customers }}</p>
            </vs-td>

            <!-- Cantidad tiendas -->
            <vs-td :data="data[indextr].quantity_shopkeepers">
              <p>
                {{ data[indextr].quantity_shopkeepers }}
              </p>
            </vs-td>

            <!-- estado -->
            <vs-td :data="data[indextr].status">
              <div
                v-if="data[indextr].status"
                style="background: #841910; color: #ffffff"
                class="w-24 p-1 mb-1 rounded-lg text-center"
              >
                {{ $t("Activo") }}
              </div>
              <div
                v-else
                style="background: #dee2e5; color: black"
                class="w-24 p-1 mb-1 rounded-lg text-center"
              >
                {{ $t("Inactivo") }}
              </div>
            </vs-td>

            <!-- editar, eliminar, guardar -->
            <vs-td :data="data[indextr].key">
              <div class="py-2 flex gap-8 items-center justify-center">
                <!-- detalles -->
                <span
                  @click="
                    $router.push({
                      path: `detail/${data[indextr].key}`,
                      append: true,
                    })
                  "
                >
                  <img
                    :src="require(`@/assets/icons/EyeBlue.svg`)"
                    class="w-6"
                  />
                </span>

                <!-- editar -->
                <span
                  @click="
                    $router.push({
                      path: `edit/${data[indextr].key}`,
                      append: true,
                    })
                  "
                >
                  <img
                    :src="require(`@/assets/icons/EditBlueIcon.svg`)"
                    class="w-5"
                  />
                </span>

                <!-- eliminar -->
                <vs-switch
                  v-model="data[indextr].status"
                  @click="deleteRule(data[indextr].key)"
                />
              </div>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>

      <vs-pagination
        :total="pagination.total_pages"
        v-model="pagination.page"
        class="mt-5"
      ></vs-pagination>
    </vx-card>
  </div>
</template>

<script>
import http from "@/http/promotions-http-client.js";

export default {
  name: "RuleListPage",
  data() {
    return {
      rules: [],
      loadingRules: false,
      loading: false,
      showDeleteModal: false,
      selectedRule: null,
      pagination: { page: 1, page_size: 20, total_pages: 1 },
      maxItemsVals: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50],
    };
  },

  async created() {
    await this.fetchRules();
  },

  watch: {
    "pagination.page"() {
      this.fetchRules();
    },
    "pagination.page_size"() {
      this.fetchRules(true);
    },
  },

  methods: {
    async fetchRules(resetPagination) {
      let paginationConfig = { ...this.pagination };
      if (resetPagination) {
        paginationConfig.page = 1;
      }
      const params = new URLSearchParams(paginationConfig).toString();

      this.loadingRules = true;
      const response = await http.get(`api/RulesPromotions?${params}`);
      this.loadingRules = false;
      if (response.status !== 200) return;
      const { result, pagination } = response.data;
      this.rules = result;
      this.pagination = {
        ...this.pagination,
        page: pagination.current,
        total_pages: pagination.total_pages,
      };
    },
    async deleteRule(ruleId) {
      if (!ruleId) return;
      const response = await http.delete(`api/RulesPromotions/${ruleId}`);
      if (response.status !== 201) return;
      this.fetchRules();
    },
  },
};
</script>

<style lang="scss">
.promotions-rules-table .vs-table--thead {
  border-bottom: 1px solid lightgray;
}
.vs-dialog-header {
  display: none !important;
}
</style>
